import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import LoginPage from '../pages/LoginPage';
import NotFoundPage from '../components/NotFoundPage';
import PrivateRoute from './PrivateRoute'
import ForgetPassword from '../pages/ForgetPassword';
import Patients from '../pages/Patients';
import Signup from '../pages/Signup';
import Audit from '../pages/Audit';
import Unblind from '../pages/Unblind';
import AdHocNotification from '../pages/AdHocNotification';
import EditPatient from '../pages/EditPatient';
import ResponseUpdate from '../pages/ResponseUpdate';

const AppRouter = ({ mode }) => {
    return (
        <BrowserRouter>
            <Switch>
                <PublicRoute path='/' component={LoginPage} exact={true} />
                <PublicRoute path='/forgotpass' component={ForgetPassword} />
                <PrivateRoute path='/patients' component={Patients} />
                <PrivateRoute path='/edit/:id' component={EditPatient} />
                <PrivateRoute path='/response/:id' component={ResponseUpdate} />
                {/* <PrivateRoute path='/unblind' component={Unblind} /> */}
                <PrivateRoute path='/signup' component={Signup} />
                <PrivateRoute path='/audit' component={Audit} />
                <PrivateRoute path='/notification' component={AdHocNotification} />
                <Route component={NotFoundPage} />
            </Switch>
        </BrowserRouter>
    )
}

const mapStateToProps = (state) => ({
    mode: state.auth.mode
});

export default connect(mapStateToProps)(AppRouter);