import React from 'react';
import { useHistory } from 'react-router-dom';
import style from './style.module.scss';

const Header = () => {

    const history = useHistory()

    const generateTitle = () => {
        // console.log("from header", history.location.pathname);
        const pathname = history.location.pathname.split('/')[1]
        switch (pathname) {
            case "patients":
                return "Patients";
            case "signup":
                return "Sign up";
            case "unblind":
                return "Unblind";
            case "audit":
                return "Audit";
            case "notification":
                return "Push notification";
            case "edit":
                return "Edit";
            case "response":
                return "Update Response";
            default:
                return "";
        }
    }

    const onBackClicked = () => {
        history.pop()
    }

    return (
        <div className={style["head-root"]}>
            {/* {history.length > 2 &&<button className={style["back-button"]} onClick={onBackClicked}>&#8592;  Back</button>} */}
            <span className={style["head-title"]}>{generateTitle()}</span>
        </div>
    );
}

export default Header;