import React from 'react';
import ClassNames from 'classnames';
import { Input } from 'reactstrap';
import style from './style.module.scss';

const Header = ({ classes, sorts, orders, sortedBy, orderedBy, orderDefault, sortDefault, query, onQueryChange, onOrderChange, onSortChange }) => {

    return (
        <div className={ClassNames(classes, style["head-root"])}>
            <div className="col-12 col-md-5">
                <Input type="text" size="sm" placeholder="Type to search..." value={query} onChange={onQueryChange} />
            </div>
            <div className="d-flex col-12 col-md-6 col-lg-4 mt-2 mt-md-0">
                {
                    !!onOrderChange && (
                        <Input type="select" size="sm" value={sortedBy} className="mr-1" onChange={onSortChange}>
                            <option value={sortDefault}>Sort by</option>
                            {sorts.map(el => (
                                <option key={el.label} value={el.value}>{el.label}</option>
                            ))}
                        </Input>
                    )
                }
                {
                    !!onSortChange && (
                        <Input type="select" size="sm" value={orderedBy} className="ml-1" onChange={onOrderChange}>
                            <option value={orderDefault}>Order</option>
                            {orders.map(el => (
                                <option key={el.label} value={el.value}>{el.label}</option>
                            ))}
                        </Input>
                    )
                }
            </div>
        </div>
    );
}

export default Header;