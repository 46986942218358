import client from '../../services/SharedConfig';

export const unblindPatient = ({body={}, header={}} = {}) => {
    const config = {
        method: 'POST',
        url: `/admin/unblind/`,
        data: body,
        headers:{
            ...header
        }
    };
    return client.request(config )
};