import client from '../../services/SharedConfig';

export const updatePatient = ({body={}, header={}, id="0"} = {}) => {
    const config = {
        method: 'POST',
        url: `/admin/patient/${id}/phase_response/`,
        data: body,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const fetPatientDetails = ({header={}, id="0"} = {}) => {
    const config = {
        method: 'GET',
        url: `/admin/patient/${id}/`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};