import React, {useState, useEffect} from 'react';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import AppRouter from './routers/AppRouter';
import {verifyUserAccess} from './actions/users';
import Loading from './components/Loading';
import {ToastContainer} from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import green from "@material-ui/core/colors/green";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from '@material-ui/core';
import * as moment from 'moment-timezone';

// moment.tz.setDefault('Etc/UTC');

const store = configureStore()

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: green,
  }
});

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  const clearStorage = () =>{
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      localStorage.removeItem("profile")
  }

  useEffect(() => {
      const access_token = localStorage.getItem('access_token');
      const refresh_token = localStorage.getItem('refresh_token');
      const profile = JSON.parse(localStorage.getItem('profile'));
      if (!access_token || !refresh_token || !profile) {
          clearStorage();
          setAuthenticated(true);
      }
      else {
        if (!!access_token){
          store.dispatch(verifyUserAccess(access_token, refresh_token, profile))
            .then(({success, error}) => {
                if (success){
                  setAuthenticated(true)
                }
                else{
                  clearStorage();
                  setAuthenticated(true)
                }
            });
        }
        else {
          clearStorage();
          setAuthenticated(true)
        }
      }
  }, [])

  return (
    <Provider store={store}>
      {authenticated ? (
        <ThemeProvider  theme={defaultMaterialTheme}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>{/* libInstance={moment} */}
            <AppRouter/>
            <ToastContainer/>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      ):(
        <Loading/>
      )}
    </Provider>
  );
}

export default App;