import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { unblindPatient } from './services';
import style from "./style.module.scss"
import Classnames from "classnames";
import { AppAlert } from '../../utils/Notification';

const AdminTrialsNew = ({ token, history }) => {

    const initCreationBody = {
        study_id: "",
        redcap_id: "",
    }

    const initialErrorState = {
        study_id: false,
        redcap_id: false,
    }

    const [isLoading, setLoading] = useState(false)
    const [creationBody, setCreationBody] = useState({ ...initCreationBody })
    const [errorStatus, setErrorStatus] = useState({ ...initialErrorState })
    const [detailInfo, setDetailInfo] = useState(undefined)
    const [unblindTarget, setUnblindTarget] = useState(false)

    const onValidate = () => {

        const keys = Object.keys(initCreationBody)
        const newState = {}
        let result = true
        keys.forEach(key => {
            if (!creationBody[key]) {
                result = false
            }
            newState[key] = (!creationBody[key])
        })
        setErrorStatus(newState)
        return result
    }

    const handleConfirmUnblind = () => {
        if (!isLoading && onValidate()) {
            setUnblindTarget(false)
            setLoading(true)
            unblindPatient({ body: creationBody, header: { authorization: token } })
                .then((response) => {
                    AppAlert(200, 'Unblind success')
                    setDetailInfo(response.data)
                })
                .catch((error) => {
                    setLoading(false)
                    AppAlert(400, error.response.data.detail || "Failed to complete operation")
                })
        }
    }

    const onFieldChange = (fieldName, e) => {
        const value = e.target.value
        // if (['study_id', 'redcap_id'].includes(fieldName)) {
        //     if (value.match(/^[0-9]*$/)) {
        //         setCreationBody({ ...creationBody, [fieldName]: value })
        //     }
        // }
        // else {
            setCreationBody({ ...creationBody, [fieldName]: value })
        // }
    }

    const toggleDetailModal = () => {
        setCreationBody({...initCreationBody})
        setErrorStatus({...initialErrorState})
        setLoading(false)
        setDetailInfo(undefined)

    };

    const closeUnblindModal = () => {
        setUnblindTarget(false)
    }

    const openUnblindModal = () => {
        setUnblindTarget(true)
    }

    return (
        <div className={style["page-root"]}>
            <Modal isOpen={unblindTarget} toggle={closeUnblindModal}>
                <ModalHeader toggle={closeUnblindModal}>Patient unblind</ModalHeader>
                <ModalBody>
                    <div className={style['normal-font']}>
                        <span>Are you sure, do you want to unblind user?</span>
                    </div>
                    <div className='d-flex justify-content-end mt-3'>
                        <button className={Classnames("btn", "btn-danger", "btn-md", "mr-1", { "disabled": isLoading })} onClick={handleConfirmUnblind}>Confirm</button>
                        <button className={Classnames("btn", "btn-secondary", "btn-md", "ml-1", { "disabled": isLoading })} onClick={closeUnblindModal}>Cancel</button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={!!detailInfo} toggle={toggleDetailModal}>
                <ModalHeader toggle={toggleDetailModal}>Patient details</ModalHeader>
                <ModalBody>
                    <div className="col-12">
                        <span className={style["info-title"]}>Study ID:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('study_id') ? detailInfo.study_id : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Redcap ID:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('redcap_id') ? detailInfo.redcap_id : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Site:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('site') ? (detailInfo.site === 1 ? 'Melbourne' : 'Adelaide') : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>UL GI:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('ul_gi') ? (detailInfo.ul_gi === 1 ? 'Upper' : 'Lower') : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Donor ID:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('donor_id') ? detailInfo.donor_id : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Batch ID:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('batch_id') ? detailInfo.batch_id : "-"}</span>
                    </div>

                    <div className="col-12">
                        <span className={style["info-title"]}>FMT placebo induction:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('fmt_placebo_induction') ? detailInfo.fmt_placebo_induction : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Induction phase response:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('induction_phase_response') ? detailInfo.induction_phase_response : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Open label:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('open_label') ? (detailInfo.open_label === true ? 'Yes' : 'No') : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>FMT placebo maintenance:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('fmt_placebo_maintenance') ? detailInfo.fmt_placebo_maintenance : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Unblinding info:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('unblinding_info') ? (detailInfo.unblinding_info === true ? 'Yes' : 'No') : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Withdrawal:</span>
                        <span className={style["info-value"]}>{!!detailInfo && detailInfo.hasOwnProperty('withdrawal') ? detailInfo.withdrawal : "-"}</span>
                    </div>
                </ModalBody>
            </Modal>
            <div className="col-12 col-md-9 mt-4">
                <div className="form-group">
                    <label for="study_id-input" className={Classnames({ [style["label-error"]]: errorStatus["study_id"] })}>Study ID</label>
                    <input type="text" className={Classnames("form-control", { [style["input-error"]]: errorStatus["study_id"] })} disabled={isLoading} id="study_id-input" value={creationBody["study_id"]} onChange={onFieldChange.bind(this, "study_id")} />
                </div>
                <div className="form-group">
                    <label for="redcap_id-input" className={Classnames({ [style["label-error"]]: errorStatus["redcap_id"] })}>Redcap ID</label>
                    <input type="text" className={Classnames("form-control", { [style["input-error"]]: errorStatus["redcap_id"] })} disabled={isLoading} id="redcap_id-input" value={creationBody["redcap_id"]} onChange={onFieldChange.bind(this, "redcap_id")} />
                </div>
            </div>
            <div className="col-12 col-md-9">
                <button className={Classnames("btn", "btn-success", "btn-md", "mr-1", { "disabled": isLoading })} onClick={openUnblindModal}>Unblind</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.access_token
});

const mapDispatchToProps = (dispatch) => ({
    // startLoginUser: (body, remember) => dispatch(startLoginUser({body}, remember))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminTrialsNew);