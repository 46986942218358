import client from './SharedConfig';

export const loginUser = ({body={}} = {}) => {
    const config = {
        method: 'POST',
        url: '/account/login/',
        data: body,
    };
    return client.request(config )
};

export const usersList = ({header={}, parameter={}} = {}) => {
    const config = {
        method: 'GET',
        url: 'users/admin/list',
        params:{
            ...parameter,
            code: process.env.REACT_APP_CODE,
        },
        headers:{
            ...header
        }
    };
    return client.request(config )
}

export const userVerification = ({header={}} = {}) => {
    const config = {
        method: 'GET',
        url: '/account/profile/',
        params:{
            code: process.env.REACT_APP_CODE,
        },
        headers:{
            ...header
        }
    };
    return client.request(config )
}

export const logoutUser = ({header={}} = {}) => {
    const config = {
        method: 'POST',
        url: 'users/logout',
        params:{
            code: process.env.REACT_APP_CODE,
        },
        headers:{
            ...header
        }
    };
    return client.request(config )
}

export const userDetails = ({header={}, userId="0"} = {}) => {
    const config = {
        method: 'GET',
        url: `users/admin/${userId}`,
        params:{
            code: process.env.REACT_APP_CODE,
        },
        headers:{
            ...header
        }
    };
    return client.request(config )
}

export const userUpdate = ({header={}, body={}, userId="0"} = {}) => {
    const config = {
        method: 'PUT',
        url: `users/admin/${userId}`,
        params:{
            code: process.env.REACT_APP_CODE,
        },
        headers:{
            ...header
        },
        data: body
    };
    return client.request(config )
}