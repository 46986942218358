import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updatePatient, fetPatientDetails } from './services';
import style from "./style.module.scss"
import Classnames from "classnames";
import { Input } from 'reactstrap';
import { AppAlert } from '../../utils/Notification';
import { getContextKeys, statusMapper as statusMapper } from '../../utils/StatusMapper';

const ResponseUpdate = ({ token, match, history }) => {

    const initCreationBody = {
        response: ""
    }

    const initialErrorState = {
        response: false,
    }

    useEffect(() => {
        setLoading(true)
        fetPatientDetails({ id: match.params.id, header: { authorization: token } })
            .then((response) => {
                const { current_phase } = response.data
                let resp = null
                if (['maintenance', 'optimization', 'open_label', 'induction'].includes(current_phase)){
                    resp = response.data[`${current_phase}_response`] || null
                    console.log('current_phase resp', current_phase, resp);
                    setCurrentPhase(current_phase)
                }
                else {
                    AppAlert(500, "Forbidden phase")
                    history.push("/patients")
                    return
                }
                setCreationBody({ response: resp })
                setLoading(false)
            })
            .catch(error => {
                if (!error.response) {
                    AppAlert(500, "cannot reach server")
                }
                else {
                    AppAlert(error.response.status, error.response.data.detail)
                }
                setLoading(false)
            })
    }, [])

    const [isLoading, setLoading] = useState(false)
    const [currentPhase, setCurrentPhase] = useState('')
    const [creationBody, setCreationBody] = useState({ ...initCreationBody })
    const [errorStatus, setErrorStatus] = useState({ ...initialErrorState })

    const onValidate = () => {

        const keys = Object.keys(initialErrorState)
        const newState = {}
        let result = true
        keys.forEach(key => {
            if (!creationBody[key]) {
                result = false
            }
            newState[key] = (!creationBody[key])
        })
        setErrorStatus(newState)
        return result
    }

    const onUpdate = () => {
        if (!isLoading) {
            setLoading(true)
            updatePatient({ id: match.params.id, body: creationBody, header: { authorization: token } })
                .then((response) => {
                    setLoading(false)
                    history.goBack()
                    AppAlert(200, 'Response update success')
                })
                .catch((error) => {
                    setLoading(false)
                    if (!!error.response.data) {
                        if (!!error.response.data.detail) {
                            AppAlert(400, error.response.data.detail)
                        }
                        else {
                            Object.keys(error.response.data).forEach((key) => {
                                AppAlert(400, error.response.data[key][0])
                            })
                        }
                    }
                    else {
                        console.log(error);
                        AppAlert(500, 'Cannot reach server')
                    }
                })
        }
    }

    const onFieldChange = (fieldName, e) => {
        const value = e.target.value
        setCreationBody({ ...creationBody, [fieldName]: value })
    }

    const closeRandomizeModal = () => {
        history.goBack()
    }

    return (
        <div className={style["page-root"]}>
            <div className="col-12 col-md-9 mt-4">
                <div className="form-group">
                    <label for="response-input" className={Classnames({ [style["label-error"]]: errorStatus["response"] })}>{!!currentPhase ? `${statusMapper('CURRENT_PHASE_CHOICE', currentPhase)} Response` : '---'}</label>
                    <Input type="select" className={Classnames("form-control", { [style["input-error"]]: errorStatus["response"] })} disabled={isLoading} id="response-input" value={creationBody["response"]} onChange={onFieldChange.bind(this, "response")}>
                        <option value="">choose an option ...</option>
                        {
                            getContextKeys('RESPONSE_CHOICE').map((objectKey, index) => (
                                <option key={index} value={objectKey}>{statusMapper('RESPONSE_CHOICE', objectKey)}</option>
                            ))
                        }
                    </Input>
                </div>
            </div>
            <div className="col-12 col-md-9">
                <button className={Classnames("btn", "btn-success", "btn-md", "mr-1", { "disabled": isLoading })} onClick={onUpdate}>Update</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.access_token
});

const mapDispatchToProps = (dispatch) => ({
    // startLoginUser: (body, remember) => dispatch(startLoginUser({body}, remember))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResponseUpdate);