import React from 'react';

const Loading = () => {

    return (
        <div className="loading__container">
            <div className="loading">
            </div>
        </div>
    );
}

export default Loading;