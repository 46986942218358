import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { fetchAudit } from './services';
import style from "./style.module.scss"
import Classnames from "classnames";
import moment from "moment";
import { AppAlert } from "../../utils/Notification";

const Audit = ({ token, history }) => {

    const [locations, setLocations] = useState([])
    const [isEnd, setEnd] = useState(false)
    const [totalCount, setTotalCount] = useState(-1)
    const [isLoading, setLoading] = useState(false)
    const [query, setQuey] = useState({ size: 10, page: 0 })

    useEffect(() => {
        if (!isLoading) {
            setLoading(true)
            fetchAudit({ parameter: { offset: query.page * query.size, limit: query.size }, header: { authorization: token } })
                .then((response) => {
                    setLocations(response.data.results || [])
                    setTotalCount(response.data.count)
                    setEnd(response.data.count <= (query.page + 1) * query.size)
                })
                .catch(error => {
                    if (!error.response) {
                        AppAlert(500, "cannot reach server")
                    }
                    else {
                        AppAlert(error.response.status, "Error getting data")
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [query])

    const onNextPage = () => {
        if (!isEnd) {
            setQuey({ ...query, page: query.page + 1 })
        }
    }

    const onPrevPage = () => {
        if (query.page > 0) {
            setQuey({ ...query, page: query.page - 1 })
        }
    }

    const onNewLocation = () => {
        history.push("/admin/medications/new")
    }

    const onPush = () => {
        history.push("/shared/medications/push")
    }

    const onRowClick = (identifier) => {
        history.push(`/admin/medications/update/${identifier}`)
    }

    return (
        <div className={style["page-root"]}>
            <div className="col-12 col-md-9 pt-4 pb-2">
            </div>
            <div className="col-12 col-md-9 table-responsive">
                <Table striped>
                    <thead>
                        <tr>
                            <th style={{ width: "150px" }}>Username</th>
                            <th style={{ width: "200px" }}>Date</th>
                            <th style={{ width: "300px" }}>Change Reason</th>
                            <th style={{ width: "150px" }}>Type</th>
                            <th style={{ width: "130px" }}>User ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locations.map((el, index) => (
                            <tr key={index}>
                                <td>{el.username || "-"}</td>
                                <td>{!!el.history_date ? moment(el.history_date).format("DD/MM/YYYY HH:mm") : "-"}</td>
                                <td>{el.history_change_reason || "-"}</td>
                                <td>{el.history_type || "-"}</td>
                                <td>{el.history_user_id || "-"}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div>
                <button className={Classnames("btn", "btn-success", "btn-md", "mr-1", { "disabled": isLoading || query.page < 1 })} onClick={onPrevPage}>Prev page</button>
                <span>{`${totalCount > 0 ? query.page + 1 : "-"}/${totalCount > 0 ? Math.ceil(totalCount / query.size) : "-"}`}</span>
                <button className={Classnames("btn", "btn-success", "btn-md", "ml-1", { "disabled": isLoading || isEnd })} onClick={onNextPage}>Next page</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.access_token
});

const mapDispatchToProps = (dispatch) => ({
    // startLoginUser: (body, remember) => dispatch(startLoginUser({body}, remember))
})

export default connect(mapStateToProps, mapDispatchToProps)(Audit);