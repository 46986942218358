import client from '../../services/SharedConfig';

export const createPatient = ({body={}, header={}} = {}) => {
    const config = {
        method: 'POST',
        url: '/admin/patient/',
        data: body,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const continuePatient = ({body={}, header={}, id=0} = {}) => {
    const config = {
        method: 'POST',
        url: `/admin/patient/${id}/continue_register/`,
        data: body,
        headers:{
            ...header
        }
    };
    return client.request(config )
};