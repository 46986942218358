/* auth actions */
export const LOGIN_ACTION = 'LOGIN_ACTION';
export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const FETCH_USERS = 'FETCH_USERS';

/* events actions */
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const ARCHIVE_EVENT = 'ARCHIVE_EVENT';
export const ADMIN_VISIT_EVENT = 'ADMIN_VISIT_EVENT';

/* eventFilters actions */
export const EVENT_RESET_FILTERS = 'EVENT_RESET_FILTERS';
export const EVENT_NEXT_PAGE = 'EVENT_NEXT_PAGE';
export const EVENT_PREV_PAGE = 'EVENT_PREV_PAGE';
export const EVENT_GOTO_PAGE = 'EVENT_GOTO_PAGE';
export const EVENT_ORDER_NEW_CREATE = 'EVENT_ORDER_NEW_CREATE';
export const EVENT_ORDER_OLD_CREATE = 'EVENT_ORDER_OLD_CREATE';
export const EVENT_ORDER_NEW_UPDATE = 'EVENT_ORDER_NEW_UPDATE';
export const EVENT_ORDER_OLD_UPDATE = 'EVENT_ORDER_OLD_UPDATE';
export const EVENT_ARCHIVE_TRUE = 'EVENT_ARCHIVE_TRUE';
export const EVENT_ARCHIVE_FALSE = 'EVENT_ARCHIVE_FALSE';
export const EVENT_ARCHIVE_ALL = 'EVENT_ARCHIVE_ALL';
export const EVENT_HIDE_ADMIN_VISITED = 'EVENT_HIDE_ADMIN_VISITED';
export const EVENT_IGNORE_ADMIN_VISITED = 'EVENT_IGNORE_ADMIN_VISITED';

/* project actions */
export const FETCH_PROJECTS = 'FETCH_PROJECTS';

/* projectDetail actions */
export const SET_PROJECT_DETAILS = 'SET_PROJECT_DETAILS';
export const CLEAR_PROJECT_DETAILS = 'CLEAR_PROJECT_DETAILS';
export const PROJECT_DETAILS_DOWNLOAD_PLOT = 'PROJECT_DETAILS_DOWNLOAD_PLOT';
export const PROJECT_DETAILS_UPDATE_STATE = 'PROJECT_DETAILS_UPDATE_STATE';

/* projectFilters actions */
export const PROJECTS_NEXT_PAGE = 'PROJECTS_NEXT_PAGE';
export const PROJECTS_PREV_PAGE = 'PROJECTS_PREV_PAGE';
export const PROJECTS_GOTO_PAGE = 'PROJECTS_GOTO_PAGE';
export const PROJECTS_ORDER_NEW_CREATE = 'PROJECTS_ORDER_NEW_CREATE';
export const PROJECTS_ORDER_OLD_CREATE = 'PROJECTS_ORDER_OLD_CREATE';
export const PROJECTS_ORDER_NEW_UPDATE = 'PROJECTS_ORDER_NEW_UPDATE';
export const PROJECTS_ORDER_OLD_UPDATE = 'PROJECTS_ORDER_OLD_UPDATE';
export const PROJECTS_STATUS_IGNORE = 'PROJECTS_STATUS_IGNORE';
export const PROJECTS_STATUS_SET = 'PROJECTS_STATUS_SET';

/* userFilters actions */
export const USERS_NEXT_PAGE = 'USERS_NEXT_PAGE';
export const USERS_PREV_PAGE = 'USERS_PREV_PAGE';
export const USERS_GOTO_PAGE = 'USERS_GOTO_PAGE';
export const USER_ORDER_NEW_CREATE = 'USER_ORDER_NEW_CREATE';
export const USER_ORDER_OLD_CREATE = 'USER_ORDER_OLD_CREATE';
export const USER_ADMIN_TRUE = 'USER_ADMIN_TRUE';
export const USER_ADMIN_FALSE = 'USER_ADMIN_FALSE';
export const USER_ALL = 'USER_ALL';

/* userDetail actions */
export const USER_DETAILS_SET = 'USER_DETAILS_SET';
export const USER_DETAILS_CLEAR = 'USER_DETAILS_CLEAR';
export const USER_DETAILS_UPDATE = 'USER_DETAILS_UPDATE';
export const USER_DETAILS_CAHNGE_PASSWORD = 'USER_DETAILS_CAHNGE_PASSWORD';

/* translation actions */
export const TRANSLATION_CHANGE_LANGUAGE = 'TRANSLATION_CHANGE_LANGUAGE';