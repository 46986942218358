const statuses = {
    'UL_CHOICE': {
        '1': 'Upper',
        '2': 'Lower',
    },
    'SITE_CHOICE': {
        '1': 'Melbourne',
        '2': 'Adelaide',
    },
    'RESPONSE_CHOICE': {
        'full': 'Full',
        'partial': 'Partial',
        'no': 'No',
    },
    'GROUP_CHOICE': {
        '1': 'FMT',
        '2': 'Placebo',
    },
    'CURRENT_PHASE_CHOICE': {
        'optimization': 'Optimization',
        'induction': 'Induction',
        'open_label': 'Open Label',
        'maintenance': 'Maintenance',
        'withdrawn': 'Withdrawn',
    },
    'SEND_TYPE': {
        'all':'All',
        'melbourne':'Melbourne',
        'adelide':'Adelaide',
        'upper':'Upper',
        'lower':'Lower',
    }
}

export function statusMapper(context, code) {
    return statuses[context][`${code}`]
}

export function getContextKeys(context) {
    return Object.keys(statuses[context])
}