import {toast} from 'react-toastify';

export function AppAlert(status, text){
    switch (status) {
        case 200:
            return toast.success(text)
        case 400: 
        case 401: 
        case 402: 
        case 403: 
        case 404: 
        case 405: 
        case 406: 
            return toast.warn(text);
        case 500:
            return toast.error(text);
        default:
            return toast.info(text);
            
    }
}