import client from '../../services/SharedConfig';

export const pushNotification = ({body={}, header={}} = {}) => {
    const config = {
        method: 'POST',
        url: `/admin/push-notification/`,
        data: body,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const getAllPatients = ({parameter={}, header={}} = {}) => {
    const config = {
        method: 'GET',
        url: '/admin/patient/',
        params: {
            ...parameter,
        },
        headers:{
            ...header
        }
    };
    return client.request(config )
};