import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Table, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { fetchPatients, fetPatientDetails, randomizeInductionOne, randomizeMaintenanceOne, randomizeInductionTwo, startDateService, removePatient, unblindService, withdrawService, randomizeMaintenanceTwo } from './services';
import style from "./style.module.scss"
import Classnames from "classnames";
import Chip from '@material-ui/core/Chip';
import _ from "lodash";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import Skeleton from 'react-loading-skeleton';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AppAlert } from "../../utils/Notification"
import { statusMapper } from '../../utils/StatusMapper';
import TableFilter from "../../components/TableFilter";

const RenderDateModalHeader = ({date}) => {

    return (
        <div className={style["dateModalTest"]}>
            <span className={style["title"]}>Set this 8 days proior to the scope date</span>
            <div className={style["dateContainer"]}>
                <span className={style["year"]}>{date.format('YYYY')}</span>
                <span className={style["date"]}>{date.format('ddd, MMM D')}</span>
            </div>
        </div>
    )
}

const Patients = ({ token, history }) => {

    const initSearchQueries = {
        size: 10,
        page: 0,
        search: "",
        sort: "0",
        dir: "0",
        lite: true,
        refresh: false
    }

    const avaiableActions = {
        induction_1: 'Randomise Induction',
        induction_2: 'Continue Randomise Induction',
        maintenance_1: 'Randomise Maintenance',
        maintenance_2: 'Continue Randomise Maintenance',
        response_update: 'Update Response',
        unblind: 'Unblind',
        withdraw: 'Withdraw',
        edit: 'Edit',
        delete: 'Delete'
    }

    const initRandomizeInfoTwo = {
        donor_id: "",
        donor_id_list: [],
        batch_id: "",
        batch_id_list: []
    }

    const initErrorRandomizeInfoTwo = {
        donor_id: false,
        batch_id: false,
    }

    const debounceRef = useRef({ latest: undefined })
    const [searchText, setSearchText] = useState("")
    const [locations, setLocations] = useState([])
    const [totalCount, setTotalCount] = useState(-1)
    const [isEnd, setEnd] = useState(false)
    const [searchQueries, setSearchQueries] = useState({ ...initSearchQueries })
    const [isLoading, setLoading] = useState(false)
    const [isLoadingDetail, setLoadingDetail] = useState(false)
    const [actionTarget, setActionTarget] = useState(undefined)
    const [detailInfo, setDetailInfo] = useState(undefined)
    const [detailTargetId, setDetailTargetId] = useState(undefined);
    const [actionRowId, setActionRowId] = useState(undefined)
    const [randomizeResultOne, setRandomizeResultOne] = useState(undefined)
    const [randomizeInfoTwo, setRandomizeInfoTwo] = useState({ ...initRandomizeInfoTwo })
    const [errorRandomizeInfoTwo, setErrorRandomizeInfoTwo] = useState({ ...initErrorRandomizeInfoTwo })
    const [continueRandomizeTrigered, setContinueRandomizeTrigered] = useState(false)
    const [startDate, setStartDate] = useState();
    const [isDateOpen, setDateOpen] = useState(false);
    const [startDateTarget, setStartDateTarget] = useState(undefined);

    useEffect(() => {
        if (!isLoading) {
            setLoading(true)
            const newSearchQuery = { lite: searchQueries.lite, page: searchQueries.page, size: searchQueries.size, offset: searchQueries.page * searchQueries.size, limit: searchQueries.size }
            if (!!searchQueries.search) {
                newSearchQuery["search"] = searchQueries.search
            }
            if (!!searchQueries.sort) {
                newSearchQuery["sort"] = searchQueries.sort
            }
            if (!!searchQueries.dir) {
                newSearchQuery["dir"] = searchQueries.dir
            }
            fetchPatients({ parameter: newSearchQuery, header: { authorization: token } })
                .then((response) => {
                    setLocations(response.data.results || [])
                    setTotalCount(response.data.count)
                    setEnd(response.data.count <= (searchQueries.page + 1) * searchQueries.size)
                })
                .catch(error => {
                    if (!error.response) {
                        AppAlert(500, "cannot reach server")
                    }
                    else {
                        AppAlert(error.response.status, "Error getting data")
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [searchQueries])

    useEffect(() => {
        if (!!detailTargetId && !actionTarget) {
            setLoadingDetail(true)
            fetPatientDetails({ id: +detailTargetId, header: { authorization: token } })
                .then((response) => {
                    setDetailInfo(response.data)
                    setLoadingDetail(false)
                })
                .catch(error => {
                    if (!error.response) {
                        AppAlert(500, "cannot reach server")
                    }
                    else {
                        AppAlert(error.response.status, error.response.data.detail)
                    }
                    setLoadingDetail(false)
                    setTimeout(() => {
                        toggleDetailModal()
                    }, 1000)
                })
        }
    }, [detailTargetId])

    const onQueryChange = (e) => {
        const val = e.target.value
        setSearchText(val)
        if (!!debounceRef.current.latest) {
            debounceRef.current.latest.cancel()
        }
        const querySearch = _.debounce(() => {
            if (searchQueries.search !== val) {
                setSearchQueries({ ...searchQueries, search: val, page: 0 })
            }
        }, 1000)
        debounceRef.current.latest = querySearch
        querySearch()
    }

    const onNextPage = () => {
        if (!isEnd) {
            setSearchQueries({ ...searchQueries, page: searchQueries.page + 1 })
        }
    }

    const onPrevPage = () => {
        if (searchQueries.page > 0) {
            setSearchQueries({ ...searchQueries, page: searchQueries.page - 1 })
        }
    }

    const handleActionClick = (item, action) => {
        setActionTarget({ item, action, openConfirm: true })
    }

    const handleStartDate = (item) => {
        setDateOpen(true)
        setStartDateTarget(item)
    }

    const handleStartDateSubmit = (startDate) => {
        setLoading(true)
        startDateService({ id: startDateTarget.id, body: { start_date: moment(startDate).utcOffset(11).format('YYYY-MM-DD') }, header: { authorization: token } })
            .then((response) => {
                AppAlert(200, 'Start date set successful')
                setLoading(false)
                setStartDateTarget(undefined)
                setStartDate(undefined)
                setSearchQueries({ ...searchQueries, refresh: !searchQueries.refresh })
            })
            .catch((error) => {
                AppAlert(400, error.response.data.detail || error.response.data.start_date && error.response.data.start_date[0] || error.response.data.time && error.response.data.time[0] || 'cannot reach server')
                setLoading(false)
                setStartDateTarget(undefined)
                setStartDate(undefined)
            })
    }

    const handleRowClick = (elementId) => {
        setDetailTargetId(elementId)
    }

    const onActionDropdownToggle = (rowId, e) => {
        e.stopPropagation()
        if (!isLoading) {
            if (!!actionRowId && actionRowId === rowId) {
                setActionRowId(undefined)
            }
            else {
                setActionRowId(rowId)
            }
        }
    }

    const toggleDetailModal = () => {
        if (!isLoading && !isLoadingDetail) {
            const ActionTargetActName = !!actionTarget && !!actionTarget.action ? actionTarget.action : undefined
            setDetailTargetId(undefined)
            setActionTarget(undefined)
            setDetailInfo(undefined)
            if (!!ActionTargetActName && ['unblind', 'withdraw', 'induction_2', 'maintenance_2'].includes(ActionTargetActName)) {
                setSearchQueries({ ...searchQueries, refresh: !searchQueries.refresh })
            }
            // setTimeout(() => {
            //     toggleDetailModal()
            // }, 1000)
        }
    };

    const onCancelAction = () => {
        setActionTarget(undefined)
    }

    const toggleRandomizeOne = () => {
        setRandomizeResultOne(undefined)
        const newAction = `${actionTarget.action.split('_')[0]}_2`
        setActionTarget({ ...actionTarget, action: newAction })
        switch (newAction) {
            case 'induction_2':
                handleInductionTwo()
                break;
            case 'maintenance_2':
                handleMaintenanceTwo()
                break;
        }
    }

    const onConfirmAction = () => {
        setActionTarget({ ...actionTarget, openConfirm: false })
        switch (actionTarget.action) {
            case 'induction_1':
                handleInductionOne()
                break;
            case 'induction_2':
                handleInductionTwo()
                break;
            case 'maintenance_1':
                handleMaintenanceOne()
                break;
            case 'maintenance_2':
                handleMaintenanceTwo()
                break;
            case 'response_update':
                handleResponseUpdate()
                break;
            case 'unblind':
                handleUnblind()
                break;
            case 'withdraw':
                handleWithdraw()
                break;
            case 'edit':
                handleEdit()
                break;
            case 'delete':
                handleDelete()
                break;
        }
    }

    const handleInductionOne = () => {
        if (!isLoading) {
            setLoading(true)
            randomizeInductionOne({ id: actionTarget.item.id, header: { authorization: token } })
                .then((response) => {
                    setLoading(false)
                    setRandomizeResultOne(['Randomise Induction success', <br />, `Induction Treatment Arm: ${statusMapper('GROUP_CHOICE', response.data.disease_group_induction)}`])
                })
                .catch((error) => {
                    setLoading(false)
                    if (!error.response) {
                        AppAlert(400, 'cannot reach server')
                    }
                    else {
                        AppAlert(400, error.response.data.detail)
                    }
                })
        }
    }

    const handleInductionTwo = () => {
        setContinueRandomizeTrigered(true)
    }

    const handleMaintenanceOne = () => {
        if (!isLoading) {
            setLoading(true)
            randomizeMaintenanceOne({ id: actionTarget.item.id, header: { authorization: token } })
                .then((response) => {
                    setLoading(false)
                    setRandomizeResultOne(['Randomise Maintenance success', <br />, `Maintenance Treatment Arm: ${statusMapper('GROUP_CHOICE', response.data.disease_group_maintenance)}`])
                })
                .catch((error) => {
                    setLoading(false)
                    if (!error.response) {
                        AppAlert(400, 'cannot reach server')
                    }
                    else {
                        AppAlert(400, error.response.data.detail)
                    }
                })
        }
    }

    const handleMaintenanceTwo = () => {
        setContinueRandomizeTrigered(true)
    }

    const handleUnblind = () => {
        if (!isLoading) {
            setLoading(true)
            setDetailTargetId(actionTarget.item.id)
            unblindService({ id: actionTarget.item.id, header: { authorization: token } })
                .then((response) => {
                    setLoading(false)
                    setDetailInfo(response.data)
                })
                .catch((error) => {
                    setLoading(false)
                    toggleDetailModal()
                    if (!error.response) {
                        AppAlert(400, 'cannot reach server')
                    }
                    else {
                        AppAlert(400, error.response.data.detail)
                    }
                })
        }
    }

    const handleWithdraw = () => {
        if (!isLoading) {
            setLoading(true)
            setDetailTargetId(actionTarget.item.id)
            withdrawService({ id: actionTarget.item.id, header: { authorization: token } })
                .then((response) => {
                    setLoading(false)
                    setDetailInfo(response.data)
                })
                .catch((error) => {
                    setLoading(false)
                    toggleDetailModal()
                    if (!error.response) {
                        AppAlert(400, 'cannot reach server')
                    }
                    else {
                        AppAlert(400, error.response.data.detail)
                    }
                })
        }
    }

    const handleEdit = () => {
        history.push(`/edit/${actionTarget.item.id}`)
    }

    const handleResponseUpdate = () => {
        history.push(`/response/${actionTarget.item.id}`)
    }


    const handleDelete = () => {
        if (!isLoading) {
            setLoading(true)
            removePatient({ id: actionTarget.item.id, header: { authorization: token } })
                .then((response) => {
                    setLoading(false)
                    setActionTarget(undefined)
                    setSearchQueries({ ...searchQueries, refresh: !searchQueries.refresh })
                    AppAlert(200, 'User is removed')
                })
                .catch((error) => {
                    setLoading(false)
                    AppAlert(400, "Failed to remove")
                })
        }
    }

    const onContinueValidate = () => {

        const keys = ['batch_id_list', 'donor_id_list']
        const newState = {
            batch_id: false,
            donor_id: false
        }
        let result = true
        if (randomizeInfoTwo.batch_id_list.length === 0) {
            newState.batch_id = true
            result = false
        }
        else {
            newState.batch_id = false
        }
        if (randomizeInfoTwo.donor_id_list.length === 0) {
            newState.donor_id = true
            result = false
        }
        else {
            newState.donor_id = false
        }
        setErrorRandomizeInfoTwo(newState)
        return result
    }

    const onContinue = () => {
        if (!isLoading && onContinueValidate()) {
            setLoading(true)
            if (actionTarget.action === 'induction_2') {
                randomizeInductionTwo({ id: actionTarget.item.id, body: { donor_ids: randomizeInfoTwo.donor_id_list, batch_ids: randomizeInfoTwo.batch_id_list }, header: { authorization: token } })
                    .then((response) => {
                        setLoading(false)
                        setDetailInfo(response.data)
                        setDetailTargetId(actionTarget.item.id)
                        closeRandomiseModal()
                    })
                    .catch((error) => {
                        setLoading(false)
                        AppAlert(400, error.response.data.detail || 'cannot reach server')
                    })
            }
            else if (actionTarget.action === 'maintenance_2') {
                randomizeMaintenanceTwo({ id: actionTarget.item.id, body: { donor_ids: randomizeInfoTwo.donor_id_list, batch_ids: randomizeInfoTwo.batch_id_list }, header: { authorization: token } })
                    .then((response) => {
                        setLoading(false)
                        setDetailInfo(response.data)
                        setDetailTargetId(actionTarget.item.id)
                        closeRandomiseModal()
                    })
                    .catch((error) => {
                        setLoading(false)
                        AppAlert(400, error.response.data.detail || 'cannot reach server')
                    })
            }
        }
    }

    const onContinueFieldChange = (fieldName, e) => {
        const value = e.target.value
        if (['donor_id', 'batch_id'].includes(fieldName)) {
            if (value.match(/^[0-9]*$/)) {
                setRandomizeInfoTwo({ ...randomizeInfoTwo, [fieldName]: value })
            }
        }
        else {
            setRandomizeInfoTwo({ ...randomizeInfoTwo, [fieldName]: value })
        }
    }

    const closeRandomiseModal = () => {
        setContinueRandomizeTrigered(false)
        setErrorRandomizeInfoTwo({ ...initErrorRandomizeInfoTwo })
        setRandomizeInfoTwo({ ...initRandomizeInfoTwo })
    }

    const onAddToList = (listName) => {
        if (!isLoading) {
            const fieldName = listName.substring(0, listName.lastIndexOf('_'))
            if (!!randomizeInfoTwo[fieldName]) {
                let infoTwoClone = { ...randomizeInfoTwo }
                infoTwoClone[listName].push(infoTwoClone[fieldName])
                infoTwoClone[fieldName] = ''
                setRandomizeInfoTwo(infoTwoClone)
            }
        }
    }

    const onRemoveFromList = (listName, value) => {
        if (!isLoading) {
            let infoTwoClone = { ...randomizeInfoTwo }
            infoTwoClone[listName] = infoTwoClone[listName].filter((el) => el !== value)
            setRandomizeInfoTwo(infoTwoClone)
        }
    }

    const onPhaseText = (currentPhase) => {

        if (currentPhase === 'induction') {
            return `Assign ${statusMapper('CURRENT_PHASE_CHOICE', currentPhase)} Antibiotic Start Date`
        }
        else {
            return `Assign Baseline CDAI Start Date ${statusMapper('CURRENT_PHASE_CHOICE', currentPhase)}`
        }
    }

    return (
        <div className={style["page-root"]}>
            <Modal isOpen={!!detailTargetId} toggle={toggleDetailModal}>
                <ModalHeader>Detailed Info</ModalHeader>
                <ModalBody>
                    {
                        isLoading || isLoadingDetail ? (
                            <Skeleton count={5} />
                        ) : (
                            <>
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('username') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Username:</span>
                                            <span className={style["info-value"]}>{detailInfo.username ? detailInfo.username : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('redcap_id') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Redcap ID:</span>
                                            <span className={style["info-value"]}>{detailInfo.redcap_id ? detailInfo.redcap_id : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('donor_ids') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Donor ID:</span>
                                            <span className={style["info-value"]}>{detailInfo.donor_ids ? detailInfo.donor_ids.join(', ') : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('batch_ids') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Batch ID:</span>
                                            <span className={style["info-value"]}>{detailInfo.batch_ids ? detailInfo.batch_ids.join(', ') : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('disease_group_induction') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Induction Treatment Arm:</span>
                                            <span className={style["info-value"]}>{detailInfo.disease_group_induction ? statusMapper('GROUP_CHOICE', detailInfo.disease_group_induction) : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('disease_group_maintenance') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Maintenance Treatment Arm:</span>
                                            <span className={style["info-value"]}>{detailInfo.disease_group_maintenance ? statusMapper('GROUP_CHOICE', detailInfo.disease_group_maintenance) : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('fmt_placebo_induction') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>FMT Placebo Induction:</span>
                                            <span className={style["info-value"]}>{detailInfo.fmt_placebo_induction ? detailInfo.fmt_placebo_induction : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('fmt_placebo_maintenance') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>FMT Placebo Maintenance:</span>
                                            <span className={style["info-value"]}>{detailInfo.fmt_placebo_maintenance ? detailInfo.fmt_placebo_maintenance : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('site') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Site:</span>
                                            <span className={style["info-value"]}>{detailInfo.site ? statusMapper('SITE_CHOICE', detailInfo.site) : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('ul_gi') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>UL GI:</span>
                                            <span className={style["info-value"]}>{detailInfo.ul_gi ? statusMapper('UL_CHOICE', detailInfo.ul_gi) : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('induction_response') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Induction Response:</span>
                                            <span className={style["info-value"]}>{detailInfo.induction_response ? statusMapper('RESPONSE_CHOICE', detailInfo.induction_response) : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('open_label_response') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Open Label Response:</span>
                                            <span className={style["info-value"]}>{detailInfo.open_label_response ? statusMapper('RESPONSE_CHOICE', detailInfo.open_label_response) : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('maintenance_response') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Maintenance Response:</span>
                                            <span className={style["info-value"]}>{detailInfo.maintenance_response ? statusMapper('RESPONSE_CHOICE', detailInfo.maintenance_response) : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('optimization_response') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Optimization Response:</span>
                                            <span className={style["info-value"]}>{detailInfo.optimization_response ? statusMapper('RESPONSE_CHOICE', detailInfo.optimization_response) : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('unblinding_info') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Unblinding Info:</span>
                                            <span className={style["info-value"]}>{((typeof detailInfo.unblinding_info) === 'boolean') ? !!detailInfo.unblinding_info ? 'Yes' : 'No' : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('pre_register') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Pre Register:</span>
                                            <span className={style["info-value"]}>{((typeof detailInfo.pre_register) === 'boolean') ? !!detailInfo.pre_register ? 'Yes' : 'No' : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('current_phase') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Current Phase:</span>
                                            <span className={style["info-value"]}>{detailInfo.current_phase ? statusMapper('CURRENT_PHASE_CHOICE', detailInfo.current_phase) : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('induction_start_date') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Induction CDAI Start Date:</span>
                                            <span className={style["info-value"]}>{detailInfo.induction_start_date ? detailInfo.induction_start_date : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('maintenance_start_date') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Maintenance CDAI Start Date:</span>
                                            <span className={style["info-value"]}>{detailInfo.maintenance_start_date ? detailInfo.maintenance_start_date : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('open_label_start_date') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Open Label CDAI Start Date:</span>
                                            <span className={style["info-value"]}>{detailInfo.open_label_start_date ? detailInfo.open_label_start_date : "-"}</span>
                                        </div>
                                    )
                                }
                                {
                                    !!detailInfo && detailInfo.hasOwnProperty('date_joined') && (
                                        <div className="col-12">
                                            <span className={style["info-title"]}>Date Joined:</span>
                                            <span className={style["info-value"]}>{detailInfo.date_joined ? moment(detailInfo.date_joined).format('YYYY-MM-DD HH:mm') : "-"}</span>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </ModalBody>
            </Modal>
            <Modal isOpen={!!actionTarget && actionTarget.openConfirm} toggle={onCancelAction}>
                {
                    !!actionTarget && (
                        <>
                            <ModalHeader toggle={onCancelAction}>{`Username: ${actionTarget.item.username}`}</ModalHeader>
                            <ModalBody toggle={onCancelAction}>
                                <div className={style['normal-font']}>
                                    <span>{`Are you sure, do you want to ${avaiableActions[actionTarget.action]}?`}</span>
                                </div>
                                <div className='d-flex justify-content-end mt-3 mb-2 mx-2'>
                                    <button className={Classnames("btn", "btn-danger", "btn-md", "mr-1", { "disabled": isLoading })} onClick={onConfirmAction}>Confirm</button>
                                    <button className={Classnames("btn", "btn-secondary", "btn-md", "ml-1", { "disabled": isLoading })} onClick={onCancelAction}>Cancel</button>
                                </div>
                            </ModalBody>
                        </>
                    )
                }
            </Modal>
            <Modal isOpen={!!randomizeResultOne} toggle={toggleRandomizeOne}>
                {
                    !!randomizeResultOne && !!actionTarget && (
                        <>
                            <ModalHeader toggle={toggleRandomizeOne}>{`${avaiableActions[actionTarget.action]}: ${actionTarget.item.username}`}</ModalHeader>
                            <ModalBody>
                                <div className={style['normal-font']}>
                                    <span>{randomizeResultOne}</span>
                                </div>
                                <div className='d-flex justify-content-end mt-3 mb-2 mx-2'>
                                    <button className={Classnames("btn", "btn-secondary", "btn-md", "ml-1", { "disabled": isLoading })} onClick={toggleRandomizeOne}>Continue</button>
                                </div>
                            </ModalBody>
                        </>
                    )
                }
            </Modal>
            <Modal isOpen={!!continueRandomizeTrigered} toggle={closeRandomiseModal}>
                {!!actionTarget &&
                    (
                        <ModalHeader toggle={closeRandomiseModal}>{`${avaiableActions[actionTarget.action]}: ${actionTarget.item.username}`}</ModalHeader>
                    )
                }
                <ModalBody>
                    <div className="form-group">
                        <label for="donor_id-input" className={Classnames(style['normal-font'], { [style["label-error"]]: errorRandomizeInfoTwo["donor_id"] })}>Donor ID</label>
                        <div class="input-group mb-3">
                            <input type="text" className={Classnames("form-control", { [style["input-error"]]: errorRandomizeInfoTwo["donor_id"] })} disabled={isLoading} id="donor_id-input" value={randomizeInfoTwo["donor_id"]} onChange={onContinueFieldChange.bind(this, "donor_id")} />
                            <div class="input-group-append">
                                <button className={Classnames("btn", "btn-outline-secondary", { "disabled": isLoading })} type="button" onClick={onAddToList.bind(this, 'donor_id_list')}>Add</button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        {
                            !!randomizeInfoTwo && randomizeInfoTwo.donor_id_list.map((el) => (
                                <Chip key={el} label={el} onDelete={onRemoveFromList.bind(this, 'donor_id_list', el)} color="primary" style={{ margin: 4, color: '#fff' }} />
                            ))
                        }
                    </div>
                    <div className="form-group">
                        <label for="batch_id-input" className={Classnames(style['normal-font'], { [style["label-error"]]: errorRandomizeInfoTwo["batch_id"] })}>Batch ID</label>
                        <div class="input-group mb-3">
                            <input type="text" className={Classnames("form-control", { [style["input-error"]]: errorRandomizeInfoTwo["batch_id"] })} disabled={isLoading} id="batch_id-input" value={randomizeInfoTwo["batch_id"]} onChange={onContinueFieldChange.bind(this, "batch_id")} />
                            <div class="input-group-append">
                                <button className={Classnames("btn", "btn-outline-secondary", { "disabled": isLoading })} type="button" onClick={onAddToList.bind(this, 'batch_id_list')}>Add</button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        {
                            !!randomizeInfoTwo && randomizeInfoTwo.batch_id_list.map((el) => (
                                <Chip key={el} label={el} onDelete={onRemoveFromList.bind(this, 'batch_id_list', el)} color="primary" style={{ margin: 4, color: '#fff' }} />
                            ))
                        }
                    </div>
                    <ModalFooter>
                        <button className={Classnames("btn", "btn-success", "btn-md", "mr-1", { "disabled": isLoading })} onClick={onContinue}>Submit</button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
            <TableFilter
                classes="col-12 col-md-9"
                textSearchField="search"
                query={searchText}
                onQueryChange={onQueryChange}
            />
            <div className="col-12 col-md-9 table-responsive" style={{minHeight: 300}}>
                <Table striped >
                    <thead>
                        <tr>
                            <th style={{ width: "120px" }}>Username</th>
                            <th style={{ width: "120px" }}>Redcap ID</th>
                            <th style={{ width: "120px" }}>Site</th>
                            <th style={{ width: "120px" }}>UL GI</th>
                            <th style={{ width: "120px" }}>Optimization Response</th>
                            <th style={{ width: "120px" }}>Induction Response</th>
                            <th style={{ width: "120px" }}>Open Label Response</th>
                            <th style={{ width: "120px" }}>Maintenance Response</th>
                            <th style={{ width: "120px" }}>Unblinding Info</th>
                            <th style={{ width: "120px" }}>Pre Register</th>
                            <th style={{ width: "120px" }}>Current Phase</th>
                            <th style={{ width: "140px" }}>Date Joined</th>
                            <th style={{ width: "140px" }}>Induction CDAI Start Date</th>
                            <th style={{ width: "140px" }}>Open Label CDAI Start Date</th>
                            <th style={{ width: "140px" }}>Maintenance CDAI Start Date</th>
                            <th style={{ width: "120px" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locations.map((el, index) => (
                            <tr key={index} onClick={handleRowClick.bind(this, el.id)}>
                                <td>
                                    <div style={{ minWidth: '140px' }}>

                                        {el.username || "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>

                                        {el.redcap_id || "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>

                                        {statusMapper('SITE_CHOICE', el.site) || "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {statusMapper('UL_CHOICE', el.ul_gi) || "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {statusMapper('RESPONSE_CHOICE', el.optimization_response) || "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {statusMapper('RESPONSE_CHOICE', el.induction_response) || "-"}
                                    </div>
                                </td>

                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {statusMapper('RESPONSE_CHOICE', el.open_label_response) || "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {statusMapper('RESPONSE_CHOICE', el.maintenance_response) || "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {el.unblinding_info === false ? "No" : "Yes"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {el.pre_register === false ? "No" : "Yes"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {statusMapper('CURRENT_PHASE_CHOICE', el.current_phase) || "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {!!el.date_joined ? moment(el.date_joined).format("DD/MM/YYYY HH:mm") : "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {!!el.induction_start_date ? el.induction_start_date : "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {!!el.open_label_start_date ? el.open_label_start_date : "-"}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ minWidth: '140px' }}>
                                        {!!el.maintenance_start_date ? el.maintenance_start_date : "-"}
                                    </div>
                                </td>

                                <td>
                                    <ButtonDropdown
                                        isOpen={!!actionRowId && actionRowId === el.id} toggle={onActionDropdownToggle.bind(this, el.id)}>
                                        <DropdownToggle
                                            caret={true}
                                            disabled={isLoading}
                                            size="sm"
                                            color="success"
                                        >
                                            Select Action
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem
                                                disabled={isLoading || el.current_phase !== 'induction'}
                                                onClick={handleActionClick.bind(this, el, 'induction_1')}
                                            >
                                                Randomise Induction
                                            </DropdownItem>
                                            {/* <DropdownItem
                                                disabled={isLoading}
                                                onClick={handleActionClick.bind(this, el, 'induction_2')}
                                            >
                                                Continue Randomise Induction
                                            </DropdownItem> */}
                                            <DropdownItem
                                                disabled={isLoading || el.current_phase !== 'maintenance'}
                                                onClick={handleActionClick.bind(this, el, 'maintenance_1')}
                                            >
                                                Randomise Maintenance
                                            </DropdownItem>
                                            {/* <DropdownItem
                                                disabled={isLoading}
                                                onClick={handleActionClick.bind(this, el, 'maintenance_2')}
                                            >
                                                Continue Randomise Maintenance
                                            </DropdownItem> */}
                                            <DropdownItem
                                                disabled={isLoading || el.current_phase === 'withdrawn'}
                                                onClick={handleActionClick.bind(this, el, 'response_update')}
                                            >
                                                {(el.current_phase === 'withdrawn') ? 'Update Response' : `Update Response ${statusMapper('CURRENT_PHASE_CHOICE', el.current_phase)}`}
                                            </DropdownItem>
                                            <DropdownItem
                                                disabled={isLoading}
                                                onClick={handleActionClick.bind(this, el, 'unblind')}
                                            >
                                                Unblind
                                            </DropdownItem>
                                            <DropdownItem
                                                disabled={isLoading}
                                                onClick={handleActionClick.bind(this, el, 'withdraw')}
                                            >
                                                Withdraw
                                            </DropdownItem>
                                            <DropdownItem
                                                disabled={isLoading}
                                                onClick={handleActionClick.bind(this, el, 'edit')}
                                            >
                                                Edit
                                            </DropdownItem>
                                            <DropdownItem
                                                disabled={isLoading || el.current_phase === 'optimization' || el.current_phase === 'withdrawn'}
                                                onClick={handleStartDate.bind(this, el)}
                                            >
                                                {(el.current_phase === 'optimization' || el.current_phase === 'withdrawn') ? 'Assign Baseline CDAI Start Date' : onPhaseText(el.current_phase)}
                                            </DropdownItem>
                                            {/* <DropdownItem
                                                disabled={isLoading}
                                                onClick={handleActionClick.bind(this, el, 'delete')}
                                            >
                                                Delete
                                            </DropdownItem> */}
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </td>
                                {/* <td><i className={Classnames("fas fa-notes-medical", style["item-icon"])}></i></td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div>
                <button className={Classnames("btn", "btn-success", "btn-md", "mr-1", { "disabled": isLoading || searchQueries.page < 1 })} onClick={onPrevPage}>Prev page</button>
                <span>{`${totalCount > 0 ? searchQueries.page + 1 : "-"}/${totalCount > 0 ? Math.ceil(totalCount / searchQueries.size) : "-"}`}</span>
                <button className={Classnames("btn", "btn-success", "btn-md", "ml-1", { "disabled": isLoading || isEnd })} onClick={onNextPage}>Next page</button>
            </div>
            <div className='d-none'>
                <DatePicker
                    ToolbarComponent={RenderDateModalHeader}
                    variant='dialog'
                    open={isDateOpen}
                    onOpen={() => setDateOpen(true)}
                    onClose={() => setDateOpen(false)}
                    value={startDate}
                    onChange={(e) => {
                        setStartDate(e)
                        handleStartDateSubmit(e)
                    }}
                    disablePast={true}
                    format="YYYY-MM-DD"
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.access_token
});

const mapDispatchToProps = (dispatch) => ({
    // startLoginUser: (body, remember) => dispatch(startLoginUser({body}, remember))
})

export default connect(mapStateToProps, mapDispatchToProps)(Patients);