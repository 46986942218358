import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { pushNotification, getAllPatients } from './services';
import { AppAlert } from '../../utils/Notification';
import Select from 'react-select';
import Chip from '@material-ui/core/Chip';
import Classnames from "classnames";
import moment from 'moment';
import { Input } from 'reactstrap';
import { DateTimePicker } from '@material-ui/pickers';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import style from "./style.module.scss"
import { getContextKeys, statusMapper } from '../../utils/StatusMapper';

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DEFAULT_DROPDOWN_VALUE = 'DEFAULT_DROPDOWN_VALUE'

const AdHocNotification = ({ token, history }) => {

    const initCreationBody = {
        condition: DEFAULT_DROPDOWN_VALUE,
        title: "",
        body: "",
        time: undefined
    }

    const initialErrorState = {
        condition: false,
        title: false,
        body: false,
        time: false,

        patient_selector: false
    }

    const [isLoading, setLoading] = useState(false)
    const [sendNow, setSendNow] = useState(false)
    const [patients, setPatients] = useState([])
    const [targetPatients, setTargetPatients] = useState([])
    const [targetApp, setTargetapp] = useState(undefined)
    const [creationBody, setCreationBody] = useState({ ...initCreationBody })
    const [errorStatus, setErrorStatus] = useState({ ...initialErrorState })

    useEffect(() => {
        setLoading(true)
        getAllPatients({ parameter: { limit: 1000, offset: 0 }, header: { authorization: token } })
            .then((response) => {
                setPatients(response.data.results.map(el => ({ label: el.username, value: el.username })))
            })
            .catch((error) => {
                if (!error.response) {
                    AppAlert(500, "cannot reach server")
                }
                else {
                    AppAlert(error.response.status, error.response.data.details)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const onValidate = () => {
        const keys = Object.keys(initCreationBody)
        const newState = {}
        let result = true
        keys.forEach(key => {
            if (key === 'time') {
                if (!sendNow && !creationBody[key]) {
                    result = false
                }
                newState[key] = (!creationBody[key])
            }
            else if (key === 'condition') {
                newState[key] = false
            }
            else if (!creationBody[key]) {
                result = false
                newState[key] = (!creationBody[key])
            }
            else {
                newState[key] = (!creationBody[key])
            }
        })
        if (creationBody.condition === DEFAULT_DROPDOWN_VALUE && targetPatients.length === 0) {
            newState["patient_selector"] = true
            AppAlert(500, "Choose at least one message receiver")
        }
        setErrorStatus(newState)
        return result
    }

    const onCreate = () => {
        // console.log('normal', moment(creationBody.time).format('YYYY-MM-DDTHH:mm:00'));
        // console.log('utc', moment(creationBody.time).utc().format('YYYY-MM-DDTHH:mm:00'));

        if (!isLoading && onValidate()) {
            setLoading(true)
            const finalizedBody = {
                title: creationBody.title,
                body: creationBody.body,

            }
            if (!sendNow) {
                finalizedBody['time'] = moment(creationBody.time).utcOffset(11).format('YYYY-MM-DDTHH:mm:00')
            }
            if (creationBody.condition !== DEFAULT_DROPDOWN_VALUE) {
                finalizedBody['condition'] = creationBody.condition
            }
            else {
                finalizedBody['study_ids'] = targetPatients.map(el => el.value)
            }
            pushNotification({ body: finalizedBody, header: { authorization: token } })
                .then((response) => {
                    AppAlert(200, sendNow ? 'notification is sent successfully' : 'notification is scheduled successfully')
                    setErrorStatus({ ...initialErrorState })
                    setCreationBody({ ...initCreationBody })
                    setPatients([...patients, ...targetPatients])
                    setTargetPatients([])
                })
                .catch((error) => {
                    AppAlert(400, error.response.data.details)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const onFieldChange = (fieldName, value) => {
        const newBody = { ...creationBody, [fieldName]: value }
        setCreationBody(newBody)
    }

    const onAddMorePatients = (value) => {
        const val = value
        setTargetPatients([...targetPatients, val])
        setPatients(patients.filter((el) => el.value !== val.value))
        setErrorStatus({ ...errorStatus, patient_selector: false })
    }

    const onDeleteChip = (value) => {
        // console.log("on delete chip", value);
        setTargetPatients(targetPatients.filter(el => el.value !== value.value))
        setPatients([...patients, value])
    }

    return (
        <div className={style["page-root"]}>
            <div className="col-12 col-md-9 mt-4">
                <div className="form-group">
                    <label for="condition-input" className={Classnames({ [style["label-error"]]: errorStatus["condition"] })}>Condition</label>
                    <Input type="select" className={Classnames("form-control", { [style["input-error"]]: errorStatus["condition"] })} disabled={isLoading} id="condition-input" value={creationBody["condition"]} onChange={(e) => onFieldChange("condition", e.target.value)}>
                        <option value={DEFAULT_DROPDOWN_VALUE}>choose a condition ...</option>
                        {
                            getContextKeys('SEND_TYPE').map((objectKey, index) => (
                                <option key={index} value={objectKey}>{statusMapper('SEND_TYPE', objectKey)}</option>
                            ))
                        }
                    </Input>
                </div>
                {
                    creationBody.condition === DEFAULT_DROPDOWN_VALUE && (
                        <>
                            <div className={Classnames("form-group", { [style["patient-container"]]: true, [style["label-error"]]: errorStatus["patient_selector"] })}>
                                <label for="patient-input" className={Classnames({ [style["label-error"]]: errorStatus["patient_id"] })}>Select receivers</label>
                                <Select
                                    options={patients}
                                    value={{ label: "Type to search for receivers", value: 0 }}
                                    onChange={onAddMorePatients}
                                    styles={{
                                        control: styles => ({ ...styles, borderColor: errorStatus.patient_selector ? '#ff0000' : '#ced4da' })
                                    }}
                                />
                            </div>
                            <div class="form-group">
                                {
                                    targetPatients.map((el) => (
                                        <Chip label={el.label} onDelete={onDeleteChip.bind(this, el)} color="default" style={{ margin: 4, backgroundColor: '#28a745', color: '#FFFFFF' }} />
                                    ))
                                }
                            </div>
                        </>
                    )
                }
                <div class="form-group">
                    <label for="title-input" className={Classnames({ [style["label-error"]]: errorStatus["title"] })}>Title</label>
                    <input type="text" className={Classnames("form-control", { [style["label-error"]]: errorStatus["title"] })} value={creationBody["title"]} onChange={(e) => onFieldChange("title", e.target.value)} id="title-input"></input>
                </div>
                <div class="form-group">
                    <label for="body-input" className={Classnames({ [style["label-error"]]: errorStatus["body"] })}>Body</label>
                    <textarea className={Classnames("form-control", { [style["label-error"]]: errorStatus["body"] })} value={creationBody["body"]} onChange={(e) => onFieldChange("body", e.target.value)} id="body-input" rows="3"></textarea>
                </div>
                {
                    !sendNow && (
                        <div>
                            <div class="form-group">
                                <label for="time-input" className={Classnames({ [style["label-error"]]: errorStatus["time"] })}>Time</label>
                                <DateTimePicker
                                    inputVariant='outlined'
                                    error={errorStatus["time"]}
                                    ampm={false}
                                    disablePast={true}
                                    disabled={isLoading}
                                    labelFunc={() => !!creationBody['time'] ? moment(creationBody['time']).format('YYYY-MM-DD HH:mm') : 'select a date and time'}
                                    // allowKeyboardControl={false}
                                    // emptyLabel='select a date and time'
                                    // format='YYYY-MM-DD HH:mm'
                                    className={Classnames("form-control")}
                                    value={creationBody["time"]} 
                                    onChange={(e) => {
                                        onFieldChange("time", e)
                                    }}
                                    id="time-input" />
                            </div>
                            {/* <span className={style['notice']}>note: selected date and time are in UTC</span> */}
                        </div>
                    )
                }
                <FormControlLabel
                    control={<GreenCheckbox checked={sendNow} onChange={() => setSendNow(!sendNow)} />}
                    label="Send Now"
                />
            </div>
            <div className="col-12 col-md-9">
                <button className={Classnames("btn", "btn-success", "btn-md", "mr-1", { "disabled": isLoading })} onClick={onCreate}>Send notification</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.access_token
});

const mapDispatchToProps = (dispatch) => ({
    // startLoginUser: (body, remember) => dispatch(startLoginUser({body}, remember))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdHocNotification);