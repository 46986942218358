import Axios from 'axios';

export const baseURL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASE_ADDRESS}`;

const client = Axios.create({
    baseURL: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_BASE_ADDRESS}`,
    // baseURL: `http://193.105.234.25`,
    responseType: "json"
});

export default client;