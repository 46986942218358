import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createPatient } from './services';
import style from "./style.module.scss"
import Classnames from "classnames";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input } from 'reactstrap';
import moment from 'moment';
import { AppAlert } from '../../utils/Notification';
import { getContextKeys, statusMapper as statusMapper } from '../../utils/StatusMapper';

const AdminTrialsNew = ({ token, history }) => {

    const initCreationBody = {
        username: "",
        redcap_id: "",
        site: "",
        ul_gi: ""
    }

    const initialErrorState = {
        username: false,
        redcap_id: false,
        site: false,
        ul_gi: false
    }

    const [isLoading, setLoading] = useState(false)
    const [registerResult, setRegisterResult] = useState(undefined)
    const [creationBody, setCreationBody] = useState({ ...initCreationBody })
    const [errorStatus, setErrorStatus] = useState({ ...initialErrorState })

    const onValidate = () => {

        const keys = Object.keys(initCreationBody)
        const newState = {}
        let result = true
        keys.forEach(key => {
            if (!creationBody[key]) {
                result = false
            }
            newState[key] = (!creationBody[key])
        })
        setErrorStatus(newState)
        return result
    }

    const onSignup = () => {
        if (!isLoading && onValidate()) {
            setLoading(true)
            createPatient({ body: creationBody, header: { authorization: token } })
                .then((response) => {
                    setLoading(false)
                    setRegisterResult(response.data)
                })
                .catch((error) => {
                    setLoading(false)
                    if (!!error.response.data) {
                        if (!!error.response.data.detail) {
                            AppAlert(400, error.response.data.detail)
                        }
                        else {
                            Object.keys(error.response.data).forEach((key) => {
                                AppAlert(400, error.response.data[key][0])
                            })
                        }
                    }
                    else {
                        AppAlert(500, 'Cannot reach server')
                    }
                })
        }
    }

    const onFieldChange = (fieldName, e) => {
        const value = e.target.value
        if (['site', 'ul_gi'].includes(fieldName)) {
            if (value.match(/^[0-9]*$/)) {
                setCreationBody({ ...creationBody, [fieldName]: value })
            }
        }
        else {
            setCreationBody({ ...creationBody, [fieldName]: value })
        }
    }

    const closeRandomizeModal = () => {
        setRegisterResult(undefined)
        setCreationBody({...initCreationBody})
        setErrorStatus({...initialErrorState})
    }

    return (
        <div className={style["page-root"]}>
            <Modal isOpen={!!registerResult} toggle={closeRandomizeModal}>
                <ModalHeader toggle={closeRandomizeModal}>New patient details</ModalHeader>
                <ModalBody>
                    <div className="col-12">
                        <span className={style["info-title"]}>Username:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('username') ? registerResult.username : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Redcap ID:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('redcap_id') ? registerResult.redcap_id : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Site:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('site') ? statusMapper('SITE_CHOICE', registerResult.site) : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>UL GI:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('ul_gi') ? statusMapper('UL_CHOICE', registerResult.ul_gi) : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Maintenance Response:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('maintenance_response') ? statusMapper('RESPONSE_CHOICE', registerResult.maintenance_response) : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Induction Response:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('induction_response') ? statusMapper('RESPONSE_CHOICE', registerResult.induction_response) : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Open Label Response:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('open_label_response') ? statusMapper('RESPONSE_CHOICE', registerResult.open_label_response) : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Unblinding Info:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('unblinding_info') ? !!registerResult.unblinding_info ? 'Yes' : 'No' : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Pre Register:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('pre_register') ? !!registerResult.pre_register ? 'Yes' : 'No' : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Current Phase:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('current_phase') ? statusMapper('CURRENT_PHASE_CHOICE', registerResult.current_phase) : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Start Date:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('start_date') ? moment(registerResult.start_date).format('YYYY-MM-DD HH:mm') : "-"}</span>
                    </div>
                    <div className="col-12">
                        <span className={style["info-title"]}>Date Joined:</span>
                        <span className={style["info-value"]}>{!!registerResult && registerResult.hasOwnProperty('date_joined') ? moment(registerResult.date_joined).format('YYYY-MM-DD HH:mm') : "-"}</span>
                    </div>
                    <ModalFooter>
                        <button className={Classnames("btn", "btn-success", "btn-md", "mr-1", { "disabled": isLoading })} onClick={closeRandomizeModal}>Close</button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
            <div className="col-12 col-md-9 mt-4">
                <div className="form-group">
                    <label for="username-input" className={Classnames({ [style["label-error"]]: errorStatus["username"] })}>Username</label>
                    <input type="text" className={Classnames("form-control", { [style["input-error"]]: errorStatus["username"] })} disabled={isLoading} id="username-input" value={creationBody["username"]} onChange={onFieldChange.bind(this, "username")} />
                </div>
                <div className="form-group">
                    <label for="redcap_id-input" className={Classnames({ [style["label-error"]]: errorStatus["redcap_id"] })}>Redcap ID</label>
                    <input type="text" className={Classnames("form-control", { [style["input-error"]]: errorStatus["redcap_id"] })} disabled={isLoading} id="redcap_id-input" value={creationBody["redcap_id"]} onChange={onFieldChange.bind(this, "redcap_id")} />
                </div>
                <div className="form-group">
                    <label for="site-input" className={Classnames({ [style["label-error"]]: errorStatus["site"] })}>Site</label>
                    <Input type="select" className={Classnames("form-control", { [style["input-error"]]: errorStatus["site"] })} disabled={isLoading} id="site-input" value={creationBody["site"]} onChange={onFieldChange.bind(this, "site")}>
                        <option value="">choose an option ...</option>
                        {
                            getContextKeys('SITE_CHOICE').map((objectKey, index) => (
                                <option key={index} value={objectKey}>{statusMapper('SITE_CHOICE', objectKey)}</option>
                            ))
                        }
                    </Input>
                </div>
                <div className="form-group">
                    <label for="ul_gi-input" className={Classnames({ [style["label-error"]]: errorStatus["ul_gi"] })}>UL GI</label>
                    <Input type="select" className={Classnames("form-control", { [style["input-error"]]: errorStatus["ul_gi"] })} disabled={isLoading} id="ul_gi-input" value={creationBody["ul_gi"]} onChange={onFieldChange.bind(this, "ul_gi")}>
                        <option value="">choose an option ...</option>
                        {
                            getContextKeys('UL_CHOICE').map((objectKey, index) => (
                                <option key={index} value={objectKey}>{statusMapper('UL_CHOICE', objectKey)}</option>
                            ))
                        }
                    </Input>
                </div>
            </div>
            <div className="col-12 col-md-9">
                <button className={Classnames("btn", "btn-success", "btn-md", "mr-1", { "disabled": isLoading })} onClick={onSignup}>Sign up</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.access_token
});

const mapDispatchToProps = (dispatch) => ({
    // startLoginUser: (body, remember) => dispatch(startLoginUser({body}, remember))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminTrialsNew);