import client from '../../services/SharedConfig';

export const fetchPatients = ({parameter={}, header={}} = {}) => {
    const config = {
        method: 'GET',
        url: '/admin/patient/',
        params: {
            ...parameter,
        },
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const fetPatientDetails = ({header={}, id="0"} = {}) => {
    const config = {
        method: 'GET',
        url: `/admin/patient/${id}/`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const removePatient = ({header={}, id="0"} = {}) => {
    const config = {
        method: 'DELETE',
        url: `/admin/patient/${id}/`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const randomizeInductionOne = ({header={}, id="0"} = {}) => {
    const config = {
        method: 'GET',
        url: `/admin/patient/${id}/randomise_induction/`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const randomizeMaintenanceOne = ({header={}, id="0"} = {}) => {
    const config = {
        method: 'GET',
        url: `/admin/patient/${id}/randomise_maintenance/`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const randomizeInductionTwo = ({header={}, body={}, id="0"} = {}) => {
    const config = {
        method: 'POST',
        url: `/admin/patient/${id}/continue_randomise_induction/`,
        data: body,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const randomizeMaintenanceTwo = ({header={}, body={}, id="0"} = {}) => {
    const config = {
        method: 'POST',
        url: `/admin/patient/${id}/continue_randomise_maintenance/`,
        data: body,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const unblindService = ({header={}, id="0"} = {}) => {
    const config = {
        method: 'POST',
        url: `/admin/patient/${id}/unblind/`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const withdrawService = ({header={}, id="0"} = {}) => {
    const config = {
        method: 'POST',
        url: `/admin/patient/${id}/withdrawn/`,
        headers:{
            ...header
        }
    };
    return client.request(config )
};

export const startDateService = ({header={}, body={}, id="0"} = {}) => {
    const config = {
        method: 'POST',
        url: `/admin/patient/${id}/set_start_date/`,
        data: body,
        headers:{
            ...header
        }
    };
    return client.request(config )
};