import client from '../../services/SharedConfig';

export const resetPassword = ({body={}} = {}) => {
    const config = {
        method: 'POST',
        url: '/api/v1/auth/reset_password',
        data: body,
        params: {
            code: process.env.REACT_APP_CODE,
        },
    };
    return client.request(config )
};