import {LOGIN_ACTION, LOGOUT_ACTION} from '../actions/constants'

const defaultState = {};

export default (state = defaultState, action) => {
    switch (action.type){
        case LOGIN_ACTION:
            return action.payload;
        case LOGOUT_ACTION:
            return {};
        default:
            return state;
    }
};