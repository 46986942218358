import React, { useState } from 'react';
import { connect } from 'react-redux';
import Classnames from "classnames";
import validator from 'validator';
import { AppAlert } from '../../utils/Notification'
import { forgotPass } from './services'
import WhiteLogo from '../../assets/images/white-logo.png';
import DoctorLogo from '../../assets/images/doctor.jpeg';
import style from './style.module.scss'


const LoginPage = ({history}) => {

    const [email, setEmail] = useState('');
    const [submiting, setSubmiting] = useState(false);

    const validation = () => {
        let isCorrect = true
        if (validator.isEmpty(email)) {
            AppAlert(500, "Enter Email")
            isCorrect = false
        }
        else if (!validator.isEmail(email)) {
            AppAlert(500, "Invalid Email")
            isCorrect = false
        }
        return isCorrect
    }

    const submitMail = (e) => {
        if (!submiting && !!validation()) {
            setSubmiting(true)
            forgotPass({ body: {email} })
                .then((response) => {
                    AppAlert(200, response.data.msg)
                    history.replace('/')
                })
                .catch((error) => {
                    !!error.response ?
                        AppAlert(400, error.response.data.msg) :
                        AppAlert(500, "cannot reach server, please call support")
                })
                .finally(() => {
                    setSubmiting(false)
                });
        }
    }

    return (
        <div className={style["root"]}>
            <div className="col-4 px-0">
                <div className={style["image-wrapper"]}>
                    <img className={style["logo"]} src={WhiteLogo} alt="logo" />
                    <img className={style["doctor-image"]} src={DoctorLogo} alt="doctor" />
                </div>
            </div>
            <div className="col-8 px-0">
                <div className={style["login-container"]}>
                    <div className={style["login-wrapper"]}>
                        <h3 className={style["title"]}>Kletch</h3>
                        <p className={style["description"]}>Enter your email to receive recovery email.</p>
                        <div>
                            <input
                                type="email"
                                className={style["login-form-input-item"]}
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className={style["button-wrapper"]}>
                            <button className={Classnames("btn", "btn-danger", "btn-md", { "disabled": submiting })} onClick={submitMail}>
                                {!!submiting ? "waiting..." : "Submit"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    // startLoginUser: (body, remember) => dispatch(startLoginUser({body}, remember))
})

export default connect(undefined, mapDispatchToProps)(LoginPage);