import client from '../../services/SharedConfig';

export const fetchAudit = ({parameter={}, header={}} = {}) => {
    const config = {
        method: 'GET',
        url: '/admin/audit/',
        params: {
            ...parameter
        },
        headers:{
            ...header
        }
    };
    return client.request(config )
};