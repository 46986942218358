import React, { useState } from 'react';
import { connect } from 'react-redux';
import Classnames from "classnames";
import validator from 'validator';
import { resetPassword } from './services';
import { AppAlert } from '../../utils/Notification'
import { startLoginUser } from '../../actions/users'
import WhiteLogo from '../../assets/images/white-logo.png';
import DoctorLogo from '../../assets/images/doctor.jpeg';
import style from './style.module.scss'
import {baseURL} from '../../services/SharedConfig'


const LoginPage = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [remember, setRemember] = useState(true);
    const [loggingIn, setLoggingIn] = useState(false);
    const [pathParams, setPathParams] = useState(new URLSearchParams(props.location.search));

    const validation = () => {
        let isCorrect = true
        if (validator.isEmpty(username)) {
            AppAlert(500, "Enter username")
            isCorrect = false
        }
        else if (validator.isEmpty(password)) {
            AppAlert(500, "Enter password")
            isCorrect = false
        }
        return isCorrect
    }

    const resetValidation = () => {
        let isCorrect = true
        if (validator.isEmpty(newPassword)) {
            AppAlert(500, "Enter password")
            isCorrect = false
        }
        else if (validator.isEmpty(newPasswordConfirm)) {
            AppAlert(500, "Invalid password confirm")
            isCorrect = false
        }
        else if (newPassword !== newPasswordConfirm) {
            AppAlert(500, "New password does not match confirm")
            isCorrect = false
        }
        return isCorrect
    }

    const doLogin = (e) => {
        if (!loggingIn && !!validation()) {
            setLoggingIn(true)
            props.startLoginUser({ username, password }, remember)
                .then(({ success, error }) => {
                    if (error) {
                        AppAlert(400, error.body ? error.body.detail : 'Login Error')
                    }
                    else {
                        AppAlert(success.status, 'Login success')
                    }
                })
                .catch((error) => {
                    AppAlert(500, "couln't reach server")
                })
                .finally(() => {
                    setLoggingIn(false)
                });
        }
    }

    const doResetPassword = (e) => {
        if (!loggingIn && !!resetValidation()) {
            setLoggingIn(true)
            resetPassword({ body: { token: pathParams.get("token"), password: newPassword } })
                .then((response) => {
                    AppAlert(200, response.data.msg)
                    props.history.replace('/')
                    setNewPassword("")
                    setNewPasswordConfirm("")
                })
                .catch((error) => {
                    !!error.response ?
                        AppAlert(400, error.response.data.msg) :
                        AppAlert(500, "cannot reach server, please call support")
                })
                .finally(() => {
                    setLoggingIn(false)
                });
        }
    }

    const forgotPass = () => {
        window.open(`${baseURL}/account/password-reset`, '_blank')
    }

    const renderLogin = () => {
        return (
            <div className={style["login-wrapper"]}>
                <h3 className={style["title"]}>Miro</h3>
                <p className={style["description"]}>Login to the dashboard in order to access to your data.</p>
                <div>
                    <input
                        type="text"
                        className={style["login-form-input-item"]}
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        className={style["login-form-input-item"]}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className={style["button-wrapper"]}>
                    <button className={Classnames("btn", "btn-danger", "btn-md", { "disabled": loggingIn })} onClick={doLogin}>
                        {!!loggingIn ? "waiting..." : "Login"}
                    </button>
                </div>
                <div className={style["button-wrapper"]}>
                    <button type="text" className={Classnames("btn", "btn-link", "btn-md", "px-0")} onClick={forgotPass}>Forgot password?</button>
                </div>
            </div>
        )
    }

    const renderPassReset = () => {
        return (
            <div className={style["login-wrapper"]}>
                <h3 className={style["title"]}>Miro</h3>
                <p className={style["description"]}>password reset</p>
                <div>
                    <input
                        type="password"
                        className={style["login-form-input-item"]}
                        placeholder="New password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        className={style["login-form-input-item"]}
                        placeholder="New password confirm"
                        value={newPasswordConfirm}
                        onChange={(e) => setNewPasswordConfirm(e.target.value)}
                    />
                </div>
                <div className={style["button-wrapper"]}>
                    <button className={Classnames("btn", "btn-danger", "btn-md", { "disabled": loggingIn })} onClick={doResetPassword}>
                        {!!loggingIn ? "waiting..." : "Update password"}
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className={style["root"]}>
            <div className="col-4 px-0">
                <div className={style["image-wrapper"]}>
                    <img className={style["logo"]} src={WhiteLogo} alt="logo" />
                    <img className={style["doctor-image"]} src={DoctorLogo} alt="doctor" />
                </div>
            </div>
            <div className="col-8 px-0">
                <div className={style["login-container"]}>
                    {
                        !!pathParams.get("token") ? renderPassReset() : renderLogin()
                    }
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    startLoginUser: (body, remember) => dispatch(startLoginUser({ body }, remember))
})

export default connect(undefined, mapDispatchToProps)(LoginPage);